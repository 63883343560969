import React, { useState } from "react"
import { Container, Row, Col, Form, FormGroup, Input, Button } from "reactstrap"
import _ from "lodash"
import Swal from "sweetalert2"
import ReCAPTCHA from "react-google-recaptcha"

import Layout from "../components/layout"
import BackgroundImage from "../components/backgroundImage"
import Image from "../components/image"
import SEO from "../components/seo"

const SITE_KEY = "6LetxLcZAAAAABJ5ztKDONESO-CZNlF69bSUXXAp" //process.env.GATSBY_RECAPTCHA_KEY

function ContactPage() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState({})
  const [state, setState] = React.useState({
    callback: "not fired",
    value: "",
    expired: false,
  })
  const _reCaptchaRef = React.createRef()

  const handleSubmit = () => {
    if (!validate()) return

    let body = new FormData(body)
    body.append("name", name)
    body.append("email", email)
    body.append("message", message)

    fetch("https://api.firstratemerchant.com/index.php", {
      method: "POST",
      mode: "no-cors",
      "Access-Control-Allow-Origin": "*",
      body,
    })
      .then(({}) => {
        setEmail("")
        setMessage("")
        setName("")
        Swal.fire({
          title: "Thank You",
          text: "We will contact you soon.",
          icon: "success",
          confirmButtonText: "Okay",
        })
      })
      .catch(({}) => {
        Swal.fire({
          title: "Error",
          text: "Something went wrong at our end. Please try again.",
          icon: "error",
          confirmButtonText: "Okay",
        })
      })
  }

  const validate = () => {
    const errors = {}
    if (_.isEmpty(name)) errors.name = "name can not be empty"
    if (!email.includes("@")) errors.email = "Please enter a valid email"
    if (_.isEmpty(email)) errors.email = "email can not be empty"
    if (_.isEmpty(message)) errors.message = "name can not be empty"
    if (_.isEmpty(state.value))
      errors.recaptcha = "please verify that you are not a robot"
    setError(errors)
    return _.isEmpty(errors)
  }

  const remvoeError = ({ target: { name } }) => {
    setError({ ...error, [name]: "" })
  }

  const handleChange = value => {
    setError({ ...error, recaptcha: "" })
    setState({ value })
    if (value === null) setState({ expired: true })
  }

  const asyncScriptOnLoad = () => {
    setState({ callback: "called!" })
  }

  return (
    <Layout>
      <SEO
        title="Contact Us"
        description="First Rate Merchant is always there for the customers. Fill the contact form and we will reach you soon."
      />
      <BackgroundImage src="contact-us-background.jpg" style={{ height: 300 }}>
        <div className="overlay h-100 d-flex align-items-center text-light">
          <h1 className="text-center w-100 display-3">Contact Us</h1>
        </div>
      </BackgroundImage>
      <Container className="my-5">
        <iframe
          title="Office Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3565.5829499978554!2d-80.26707328487751!3d26.661832383236234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d92ff01b348051%3A0x6e2a7d3a22d12758!2s1300+Corporate+Center+Way+%23105c%2C+Wellington%2C+FL+33414!5e0!3m2!1sen!2s!4v1479425071387"
          className="map-iframe"
          height={450}
          allowFullScreen
        ></iframe>
        <Row className="py-5">
          <Col xs={12} md={6} className="pr-md-0">
            <div className="p-4 p-md-5 justify-content-center d-flex flex-column contact-form">
              <h4 className="text-color-secondary">Send us a Message</h4>
              <Form className="mt-4">
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    onFocus={remvoeError}
                    value={name}
                    onChange={({ target: { value } }) => setName(value)}
                    placeholder="Your Name"
                  />
                  {error.name && (
                    <span className="text-danger">
                      <small>{error.name}</small>
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onFocus={remvoeError}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                  {error.email && (
                    <span className="text-danger">
                      <small>{error.email}</small>
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="textarea"
                    name="message"
                    rows="5"
                    placeholder="Your Question"
                    className="text-area"
                    value={message}
                    onFocus={remvoeError}
                    onChange={({ target: { value } }) => setMessage(value)}
                  />
                  {error.message && (
                    <span className="text-danger">
                      <small>{error.message}</small>
                    </span>
                  )}
                </FormGroup>
                <FormGroup>
                  <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    theme="light"
                    ref={_reCaptchaRef}
                    sitekey={SITE_KEY}
                    onChange={handleChange}
                    asyncScriptOnLoad={asyncScriptOnLoad}
                  />
                  <br />
                  {error.recaptcha && (
                    <span className="text-danger">
                      <small>{error.recaptcha}</small>
                    </span>
                  )}
                </FormGroup>
                <Button
                  className="mt-3"
                  size="md"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Send Message
                </Button>
              </Form>
            </div>
          </Col>
          <Col xs={12} md={6} className="pl-md-0">
            <div className="p-4 p-md-5 w-100 text-light background-color-secondary h-100">
              <h4>Contact Details</h4>
              <div className="mt-5">
                <div className="d-flex align-items-center mt-4">
                  <Image src="pin.png" className="mr-4 contactus-icons" />
                  1300 CORPORATE CENTER WAY SUITE # 105C WELLINGTON, FL 33414
                </div>
                <div className="d-flex align-items-center mt-4">
                  <Image src="postal.png" className="mr-4 contactus-icons" />
                  <a
                    href="mailto:sales@firstratemerchant.com"
                    className="text-light"
                  >
                    sales@firstratemerchant.com
                  </a>
                </div>
                <div className="mt-5 mb-5 d-flex justify-content-center w-100"></div>
              </div>
              <div className="p-3" />
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ContactPage
